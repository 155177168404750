'use strict';

window.console || (window.console = {
	log: function log() {}
});
var ADSEC = window.ADSEC || null;
var cssua = window.cssua || null;

(function ($) {
	var htmlHasClass = function htmlHasClass(_class) {
		return $('html').hasClass(_class);
	};
	var siteName = '';
	var a = 'is-active';
	var v = 'is-visible';
	var f = 'is-fixed';
	var slideSpeed = '400';

	ADSEC = {
		va: {
			pathname: location.pathname,
			isMobile: htmlHasClass('ua-mobile'),
			device: {
				desktop: 1024,
				sp: 768
			},
			window: {
				width: $(window).width(),
				height: $(window).height()
			}
		},
		localDecision: function localDecision() {
			var regExp = new RegExp('(test\\.' + siteName + '\\.connecty\\.co\\.jp|copre\\.jp|localhost|192\\.168\\.0\\.|192\\.168\\.11\\.|192\\.168\\.1\\.)', 'g');
			return regExp.test(location.hostname);
		},
		localLoading: function localLoading() {
			var _this = this;
			// ローカル環境ならincludeをAjaxで読み込む
			var key = 'inc_';
			var ajaxArray = [];
			var ajaxArrayList = [];
			var i, j;
			var includeClass = document.querySelectorAll('[class*="' + key + '"]');
			var includeClassLen = includeClass.length;

			for (i = 0; i < includeClassLen; i++) {
				var path = includeClass[i].innerHTML.split(' ')[1];
				ajaxArray.push(path);
			}
			var ajaxLen = ajaxArray.length;

			if (ajaxLen > 0) {
				for (i = 0; i < ajaxLen; i++) {
					ajaxArrayList[i] = $.ajax({
						type: 'GET',
						url: ajaxArray[i] + '.html',
						dataType: 'html',
						timeout: 5000
					});
				}
				$.when.apply($, ajaxArrayList).done(function () {
					for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
						args[_key] = arguments[_key];
					}

					var regExp = new RegExp(key);

					for (i = 0; i < args.length; i++) {
						var result = args[i];
						var position = $(result[0]).filter(':first').attr('class').split(' ');

						for (j = 0; j < position.length; j++) {
							if (position[j].match(regExp)) {
								position = position[j];
								break;
							}
						}
						$('.' + position).html(result[0]).children().unwrap();

						console.log('Succeeded to read the include file!:', position);
					}
					_this.loadDelayScript();
				});
			} else {
				_this.loadDelayScript();
			}
		},
		catchAjaxError: function catchAjaxError(_errorThrown) {
			if (window.ga) {
				window.ga('send', 'event', 'ajaxError', location.href, _errorThrown, 0, true);
			}
		},
		getQuery: function getQuery() {
			var vars = [];
			var hash = null;
			var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
			for (var i = 0; i < hashes.length; i++) {
				hash = hashes[i].split('=');
				vars.push(hash[0]);
				vars[hash[0]] = hash[1];
			}
			return vars;
		},
		nextToggle: function nextToggle() {
			var $switch = $('.js-toggle');

			$switch.on('click', function () {
				$(this).toggleClass(a);
				$(this).next('.js-toggleBlock').stop().slideToggle(slideSpeed);
			});
		},
		easeScroll: function easeScroll() {
			$('a[rel="scroll"]').on('click', function (event) {
				var speed = 1200;
				if (/#/.test(event.currentTarget.href)) {
					scrollToObject(event.currentTarget.href, speed);
				}
				return false;
			});
			function scrollToObject(_self, _speed) {
				var hash = '#' + _self.split('#')[1];
				var pos = hash === '#pagetop' ? 0 : $(hash).offset().top;

				if (hash !== '#pagetop') {
					if (ADSEC.va.window.width <= ADSEC.va.device.desktop) {
						pos -= 60;
					} else {
						pos -= 100;
					}
				}

				$('html,body').stop().animate({ scrollTop: pos }, _speed, 'easeOutExpo');
			}
		},
		easeScrollHash: function easeScrollHash() {
			var hash = location.hash;
			if (hash.length) {
				$('html, body').css('display', 'none');
				setTimeout(function () {
					$('html, body').css('display', 'block');

					var _hash = '#' + hash.split('#')[1];
					var pos = $(_hash).offset().top;

					if (ADSEC.va.window.width < ADSEC.va.device.desktop) {
						pos -= 60;
					} else {
						pos -= 100;
					}
					$('html,body').stop().animate({ scrollTop: pos }, 1200, 'easeOutExpo');
				}, 100);
			}
		},
		pageTop: function pageTop() {
			var $pagetop = $('.footer-pagetop');

			$(window).scroll(function (e) {
				var $this = $(e.currentTarget);
				var $footer = $('.footer');
				var $footerTop = $('.footer-top');
				var footerPos = $footerTop.offset().top + $footerTop.height();
				var thisScroll = $this.scrollTop();
				var pagetopPos = $this.height() + $footerTop.height() + thisScroll;

				if (thisScroll > '200') {
					$pagetop.addClass(v);
				} else {
					$pagetop.removeClass(v);
				}

				if (footerPos <= pagetopPos) {
					$pagetop.addClass(a);
				} else {
					$pagetop.removeClass(a);
				}
			});
		},
		modal: function modal() {
			$('.mod-modal').on('click', function (e) {
				var HTML = '<div class="mod-modal-overlay">';
				HTML += '<div class="mod-modal-closeArea"></div>' + '<span class="mod-modal-closeBtn"><span></span></span>' + ($(e.currentTarget).find('img').prop('outerHTML') + '</div>');
				$('body').append(HTML);
				var $overlay = $('.mod-modal-overlay');
				$overlay.addClass(a);
				setTimeout(function () {
					$overlay.addClass(v);
					$('.mod-modal-closeArea, .mod-modal-closeBtn').on('click', function () {
						$overlay.removeClass(v);
						setTimeout(function () {
							$overlay.removeClass(a);
							$overlay.remove();
						}, 400);
					});
				});
			});
		},
		headerMenu: function headerMenu() {
			var $header = $('.header');
			var m = 'is-move';
			var s = 'is-scroll';
			var $overlay = $('.header-search-overlay');
			var $close = $('.header-search-close');
			var $siteSearch = $('.header-nav-siteSearch');
			var timer = void 0,
			    flag = void 0;

			if (!$('body').hasClass('header-white')) {
				$header.addClass(m);
			}
			// スクロール挙動
			$(window).scroll(function (e) {
				var thisScroll = $(e.currentTarget).scrollTop();
				if (timer) clearTimeout(timer);
				if (thisScroll === 0 && flag) {
					if ($('body').hasClass('header-white')) {
						$header.removeClass(m);
					}
					$header.removeClass(s);
					flag = false;
				} else if (thisScroll > 0 && !flag) {
					if ($('body').hasClass('header-white')) {
						$header.addClass(m);
					}
					$header.addClass(s);
					flag = true;
				}
			});

			// <PC>メガメニュー start --------------------------------------
			$('.js-megamenu, .js-megamenu-block').on('mouseover', function (e) {
				if (!$header.hasClass(s)) {
					$header.addClass(m);
				}
			});
			$('.js-megamenu, .js-megamenu-block').on('mouseleave', function (e) {
				if (!$header.hasClass(s)) {
					if ($('body').hasClass('header-white')) {
						$header.removeClass(m);
					}
				}
			});
			$('.js-megamenu-block').on('mouseover', function (e) {
				var $this = $(e.currentTarget);
				$this.addClass(a);
				$this.prev('.js-megamenu').addClass(a);
			});
			$('.js-megamenu-block').on('mouseleave', function (e) {
				var $this = $(e.currentTarget);
				$this.removeClass(a);
				$this.prev('.js-megamenu').removeClass(a);
			});
			// <PC>メガメニュー end --------------------------------------

			// <TABELT/SP> start --------------------------------------------
			// <TABELT/SP>サブメニュー開閉
			$('.js-spMenu-open').on('click', function (e) {
				var $this = $(e.currentTarget);
				$this.toggleClass(a);
				$this.next('.js-megamenu').next('.js-megamenu-block').slideToggle();
			});

			// <TABELT/SP>メニュー内アニメーション
			var scrollTopNow = void 0;

			$('.header-menuBtn').on('click', function (e) {
				var $this = $(e.currentTarget);
				var delay = .1;

				$this.toggleClass(a);
				$header.toggleClass('is-menuOpen');

				if ($this.hasClass(a)) {
					// 開く
					$('.header-nav-bg').addClass(a);
					$('.header-nav').addClass(a).delay(100).queue(function (next) {
						$('.js-delay-item').addClass(v);
						$('.js-delay-item').each(function (idx, ele) {
							delay += 0.15;
							$(ele).css('transition-delay', delay + 's');
						});
						next();
					});

					fixedOnContainer();
				} else {
					// 閉じる
					$('.js-delay-item').css('transition-delay', 0 + 's').removeClass(v);
					$('.header-nav-bg').css('transition-delay', .2 + 's').removeClass(a).delay(300).queue(function (next) {
						$('.header-nav').removeClass(a);
						next();
					});
					fixedOffContainer();
				}
			});

			// サイト内検索（PCのみ）
			if (ADSEC.va.window.width > 1024) {
				var moveFlg = function moveFlg() {
					if ($('.header').hasClass('is-move')) {
						return true;
					}
				};
				$('.js-header-siteSearch').on('click', function () {
					var headerFlg = moveFlg();
					fixedOnContainer();
					$overlay.addClass(a);
					$siteSearch.addClass(a);
					$close.addClass(a);
					setTimeout(function () {
						$overlay.addClass(v);
						$siteSearch.addClass(v);
						$close.addClass(v);
						if (headerFlg === true) {
							$('.header').addClass('is-move');
						}
					});
				});
				$overlay.on('click', function () {
					$siteSearch.removeClass(v);
					$close.removeClass(v);
					$overlay.removeClass(v);
					setTimeout(function () {
						$siteSearch.removeClass(a);
						$close.removeClass(a);
						$overlay.removeClass(a);
					}, 400);
					fixedOffContainer();
				});
				$close.on('click', function () {
					$siteSearch.removeClass(v);
					$close.removeClass(v);
					$overlay.removeClass(v);
					setTimeout(function () {
						$siteSearch.removeClass(a);
						$close.removeClass(a);
						$overlay.removeClass(a);
					}, 400);
					fixedOffContainer();
				});
			}

			var fixedOnContainer = function fixedOnContainer() {
				scrollTopNow = $(window).scrollTop();
				$('.container').css({
					position: 'fixed',
					width: '100%',
					top: -1 * scrollTopNow,
					left: 0
				});
				$('body').addClass(f);
			};
			var fixedOffContainer = function fixedOffContainer() {
				$('.container').css({
					position: 'static'
				});
				$('body').removeClass(f);
				$(window).scrollTop(scrollTopNow);
			};
			// <TABELT/SP> end --------------------------------------------

			// カレント
			var $current = location.pathname.split('/')[1];
			$('.js-current-item[href="/' + $current + '/"]').addClass(a);
		},
		footerMenu: function footerMenu() {
			$('.js-footerMenu-open').on('click', function (e) {
				var $this = $(e.currentTarget);
				$this.toggleClass(a);
				$this.parents('.footer-top-head').next('.js-footerMenu').slideToggle();
			});
		},
		sideNav: function sideNav() {
			if ($('.mod-sideNav-nav').length) {
				$('a.mod-sideNav-nav-ul-li-a').each(function (idx, ele) {
					var thisHref = $(ele).attr('href');
					if (thisHref === ADSEC.va.pathname || thisHref + 'index.html' === ADSEC.va.pathname) {
						$(ele).addClass(a);
					}
				});
			}
		},
		tabChange: function tabChange() {
			if ($('.mod-tab').length) {
				if ($('.mod-tab').eq(0).hasClass('js-tabChange')) {
					var $hash = location.hash;
					if ($hash.length) {
						$('.mod-tab-item-btn').each(function (idx, ele) {
							if ($(ele).attr('data-name') === $hash.replace('#', '')) {
								$(ele).addClass(a);
								$('.mod-tab-content').eq(idx).addClass(a);
							}
						});
					} else {
						$('.mod-tab-content').eq(0).addClass(a);
						$('.mod-tab-item-btn').eq(0).addClass(a);
					}
					$('.mod-tab-item-btn').each(function (idx, val) {
						$(val).on('click', function () {
							$('.mod-tab-item-btn').removeClass(a);
							$(val).addClass(a);
							$('.mod-tab-content').removeClass(a);
							$('.mod-tab-content').eq(idx).addClass(a);
						});
					});
				}
			}
		},
		siteSearch: function siteSearch() {
			$('.js-siteSearch-submit').on('click', function (e) {
				if ($(e.currentTarget).prev('.js-siteSearch-input').val()) {
					window.open('https://www.google.co.jp/search?sitesearch=www.adsec.jp&q=' + encodeURIComponent($(e.currentTarget).prev('.js-siteSearch-input').val()), '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
				} else {
					window.open('https://www.google.co.jp/search?sitesearch=www.adsec.jp', '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
				}
			});
			$('.js-siteSearch-input').on('keydown', function (e) {
				if (e.keyCode === 13) {
					if ($(e.currentTarget).val()) {
						window.open('https://www.google.co.jp/search?sitesearch=www.adsec.jp&q=' + encodeURIComponent($(e.currentTarget).val()), '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
					} else {
						window.open('https://www.google.co.jp/search?sitesearch=www.adsec.jp', '_blank', 'location,status,menubar,toolbar,scrollbars,resizable,noopener');
					}
				}
			});
		},
		loadDelayScript: function loadDelayScript() {
			var _this = this;
			_this.nextToggle();
			_this.easeScroll();
			_this.easeScrollHash();
			_this.pageTop();
			_this.modal();
			_this.headerMenu();
			_this.footerMenu();
			_this.sideNav();
			_this.tabChange();
			_this.siteSearch();

			svg4everybody();
		}
	};

	$(function () {
		return ADSEC.localDecision() ? ADSEC.localLoading() : ADSEC.loadDelayScript();
	});
})(window.jQuery);